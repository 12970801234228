import React from 'react';
import * as css from './footer.module.css';
import Displayer from 'components/generic/Displayer';

const currentYear = new Date().getFullYear();

//  | All Rights Reserved -? may be used when photos are from other sites ?
const Footer = () => (
  <Displayer>
    <div className={css.footer} style={{ textAlign: 'center' }}>
      &copy; 2016-{currentYear} Nexit s.r.o.
    </div>
  </Displayer>
);

export default Footer;
