import React from 'react';
import * as css from './splashHeading.module.css';
import Logo from './Logo';

export default function SplashHeading() {
  return (
    <div className={css.heading}>
      <Logo className={css.logo} />
      <div className={css.text}>
        Nex<span className={css.letterI}>i</span>t
      </div>
    </div>
  );
}
