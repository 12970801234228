import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import { StaticQuery, graphql } from 'gatsby';

import * as css from './index.module.css';

/**
 * TemplateWrapper is automatically loaded by Gatsby for every page
 */
const TemplateWrapper = ({ children, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              author
              keywords
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        const { siteMetadata } = data.site;
        return (
          <div>
            <Helmet
              meta={[
                { name: 'author', content: siteMetadata.author },
                { name: 'keywords', content: siteMetadata.keywords },
                // TODO: <meta property="og:url" content="" />
                { name: 'og:type', content: 'website' },
                {
                  name: 'og:image',
                  content:
                    'https://jurosh-webs.s3.amazonaws.com/nexit/logo/nexit-1200.png',
                },
                // App theme (mobile devices)
                { name: 'theme-color', content: '#3498db' }, // Chrome, Firefox OS and Opera
                { name: 'msapplication-navbutton-color', content: '#3498db' }, // Windows Phone
                {
                  name: 'apple-mobile-web-app-status-bar-style',
                  content: '#3498db',
                }, // iOS Safari
                {
                  name: 'google-site-verification',
                  content: 'vDntNu_N4XDfHdFUGjq0WJOyBofY-_R3rgGUNbayFJY',
                },
              ]}
              link={[
                {
                  rel: 'canonical',
                  href:
                    siteMetadata.siteUrl +
                    (location.pathname === '/' ? '' : location.pathname),
                },
                {
                  rel: 'stylesheet',
                  href:
                    'https://fonts.googleapis.com/css?family=Jura:300,400,500,600&amp;subset=latin-ext',
                },
              ]}
            />
            <Header />
            <div className={css.content}>
              {children}
              <br />
              <br />
              <Footer />
            </div>
          </div>
        );
      }}
    />
  );
};

TemplateWrapper.propTypes = {
  location: PropTypes.object,
  children: PropTypes.any,
  data: PropTypes.any,
};

export default TemplateWrapper;
