import React from 'react';
import { Link } from 'gatsby-link';
import SplashHeading from 'components/headings/SplashHeading';
import * as css from './header.module.css';

const linkTo = (to: string, name: string) => (
  <Link to={to} className={css.navLink}>
    {name}
  </Link>
);

const Header = () => (
  <div className={css.wrap}>
    <div className={css.innerWrap}>
      {linkTo('/', 'Home')} {linkTo('/about', 'About')}{' '}
      {linkTo('/references', 'References')}{' '}
      {linkTo('/frontend-news', '#FrontendNews')}{' '}
      <div className={css.logoWrap}>
        <Link to="/">
          <SplashHeading />
        </Link>
      </div>
    </div>
  </div>
);

export default Header;
