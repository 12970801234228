import React from 'react';
import * as css from './logo.module.css';

const Logo = ({ className }) => (
  <div className={css.logo + ' ' + className}>
    <div className={css.whiteSquare} />
    <div className={css.square} />
    <div className={css.bottomSquare} />
  </div>
); //  | All Rights Reserved -? may be used when photos are from other sites

export default Logo;
